<template>
    <div class="mb-24">
        <loader
            class="z-50"
            :loading="loading"
            :backdrop="true"
        />
        <div id="bluemoon-guarantor-container"></div>
    </div>
</template>

<script>
import Loader from '@/components/ui/Loader';
import { mapGetters } from 'vuex';
export default {
    name: 'GuarantorLease',
    components: { Loader },
    data() {
        return {
            loading: false,
            guarantor: null,
            propertyNumber: '15430',
        };
    },

    computed: {
        ...mapGetters({
            getGuarantor: 'application/getGuarantor',
        }),
    },

    beforeMount() {
        if (this.getGuarantor) this.guarantor = this.getGuarantor;
        this.getAuthBlueMoonToken();
    },

    methods: {
        getAuthBlueMoonToken() {
            this.loading = true;
            this.$bmServiceDataProvider
                .create('authTokenBM', {
                    data: {
                        username: 'quextUser@TX05101252', // Fake property (15430) serial number
                        password: 'Madera1234',
                        scope: `guarantor:${this.guarantor.key}`,
                        grant_type: 'password',
                        client_id: 4,
                        client_secret: 'XgNRCbNfllV5inyfxS0zB1vbhhsG1FSAXwQptDbN',
                    },
                })
                .then(async res => {
                    this.token = res.access_token;
                    await this.leaseGuarantor(res.access_token);
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        leaseGuarantor(token) {
            const params = {
                guarantorKey: this.guarantor.key,
                view: 'form',
                fieldData: {
                    guarantor_name: this.guarantor.guarantor_name,
                    guarantor_email: this.guarantor.guarantor_email,
                    guarantor_phone: this.guarantor.guarantor_phone,
                },

                redirectUrl: this.$route.fullPath,
            };
            // eslint-disable-next-line no-undef
            const bluemoonElements = new BluemoonElements(token, this.propertyNumber);

            const guarantorElement = bluemoonElements.load('bluemoon-guarantor-container', 'bm-guarantor', params);

            guarantorElement.addEventListener('onElementLoaded', () => {
                this.loading = false;
            });
            guarantorElement.addEventListener('onGuarantorSaved', e => {
                console.log('onGuarantorSaved', e.detail);
            });
        },
    },
};
</script>
